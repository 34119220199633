export const TaskStatus = { //任务状态
    jinxingzhong:2,
    yiwancheng:1,
    weiwancheng:0,
    yizuofei:-1
};

/**
 * 医院结构
 */
export class Hospital{
  constructor(){

      this.organizationId = null;
      this.nature = null;
      this.hospitalId = null;
      this.hospitalName = null;
      this.level = null;
      this.area = [];
      this.province = null;
      this.city = null;
      this.county = null;
      this.town = null;
      this.address = null;
      this.phone = null;
      this.website = null;
      this.isRegistered = null;
      this.hospitalUrlShow = null;
      this.hospitalUrl = null;
      this.introduction = null;
      this.isSync=null;
  }
  reset(){
      this.constructor();
  }
};

/**
 * 挂号专家
 */
export class Expert{
    constructor(){
        this.doctorId = null;
        this.doctorName = null;
        this.birthday = null;
        this.title = null;
        this.introduction = null;
        this.headUrl = null;
        this.showHeadUrl = null;
    }
    reset(){
        this.constructor();
    }
}
<template>
	<div>
		<div class="toolBar mBottom10">
			<el-button type="primary" size='small' plain @click="add">新增接入配置</el-button>
			<el-button type="primary" size='small' plain @click="edit">编辑接入配置</el-button>
			<el-button type="primary" size='small' plain @click="delConfig">删除接入配置</el-button>
			<el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
		</div>

		<el-card>
			<el-table size="small" :stripe=true height="250" :data="configTableData" style="width: 100%" highlight-current-row @current-change="handleCurrentChange" @row-click="hospitalRowClick">

				<el-table-column type="index" label="序号" width="50" align="center" />
				<el-table-column prop="orgName" label="系统机构" width="100" align="center" />
				<el-table-column v-if="false" prop="configId" label="配置ID" align="center" />
				<el-table-column v-if="false" prop="global.orgId" label="机构ID" align="center" />
				<el-table-column v-if="false" prop="hospitalId" label="医院id" align="center" />
				<el-table-column prop="hospitalName" label="接入医院" width="100" align="center" />
				<el-table-column prop="hospitalCode" label="医院代码" width="100" align="center" />
				<el-table-column prop="url" label="接入地址" width="180" align="center" />
				<el-table-column prop="appKey" label="接入密钥" width="250" align="center" />

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<!--<el-button-->
						<!--size="mini"-->
						<!--type='text'-->
						<!--@click="showHisHospital(scope.$index, scope.row,false)">查看</el-button>-->
						<el-button size="mini" type='text' v-on:click.stop="synHospital(scope.row)">同步医院数据</el-button>
						<el-button size="mini" type='text' v-on:click.stop="delHospital(scope.row)">删除医院数据</el-button>
						<el-button size="mini" type='text' v-on:click.stop="synDepartment(scope.row)">同步科室信息</el-button>
						<el-button size="mini" type='text' v-on:click.stop="synRegisterd(scope.row)">同步挂号科室</el-button>
						<el-button size="mini" type='text' v-on:click.stop="synExpert(scope.row)">同步医生信息</el-button>
						<el-button size="mini" type='text' v-on:click.stop="synPatient(scope.row)">同步随访患者信息</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<el-tabs type="border-card" style="margin-top: 8px;">
			<el-tab-pane label="科室信息">
				<el-table :data="departmentTableData" stripe height="420" style="width: 100%"
						  row-key="depId" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column type="index" width="50" align="center" />
					<el-table-column prop="hospitalDepId" label="科室ID" width="300" align="center" />
					<el-table-column prop="depName" label="科室名称" align="center" />
					<el-table-column prop="depCode" label="科室代码" align="center" />
					<el-table-column prop="isRegistered" label="名医坐诊科室" :formatter="formatRegister" align="center"></el-table-column>
				</el-table>
			</el-tab-pane>

			<el-tab-pane label="专家信息">
				<el-table :stripe=true height="420" class="w100" :data="expertList">
					<el-table-column type="index" width="50" align="center" />
					<el-table-column label="头像" align="center">
						<template slot-scope="scope">
							<img :src="scope.row.showHeadUrl" width="60px" height="60px" />
						</template>
					</el-table-column>

					<el-table-column label="姓名" prop="doctorName" align="center"></el-table-column>
					<el-table-column prop="sex" label="性别" align="center" :formatter="formatSex" />
					<el-table-column label="职称" prop="title" align="center" :formatter="formatTitle"></el-table-column>
					<el-table-column label="出生日期" prop="birthday" align="center"></el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>

		<!--配置弹出框-->
		<el-dialog :title=title :visible.sync="formVisible" :close-on-click-modal="false" v-dialogDrag>
			<el-form size="small" :model="formConfig" label-width="100px" :rules="formRules" ref="cfg">
				<el-row>
					<el-col :sm="24">
						<el-form-item label="机构：" prop="orgId">
							<el-cascader :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false"
										 v-model="formConfig.orgId"  @change="handleChange" style="width: 100%" size='small'></el-cascader>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="接口地址：" prop="url">
							<el-input clearable v-model="formConfig.url" placeholder="http://example.com" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-col :sm="24">
					<el-form-item label="接口密钥：" prop="appKey">

						<el-input readonly v-model="formConfig.appKey" />
						<!--<el-row >-->
						<!--<el-col :sm="19">-->
						<!--<el-input readonly v-model="formConfig.appKey"/>-->
						<!--</el-col>-->
						<!--<el-col :sm="4">-->
						<!--<el-button type="primary" @click="generateAppKey" style="margin-left: 20px">随机生成</el-button>-->
						<!--</el-col>-->
						<!--</el-row>-->
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="cancel('cfg')">取消</el-button>
				<el-button size="small" type="primary" @click.native="submitForm('cfg')">确定</el-button>
			</div>
		</el-dialog>

		<!-- 医院编辑、查看dlg -->
		<el-dialog title="医院操作" v-dialogDrag :visible.sync="isShowHisHospital">
			<el-form v-model="hisHospital" class="w100">
				<el-row :gutter="20">
					<el-col :sm="12">
						<el-form-item label="医院名称">
							<el-input v-model="hisHospital.hospitalName" readonly></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="医院等级">
							<el-select v-model="hisHospital.level" class="w100">
								<el-option v-for="item in global.DictionaryData['hospitalLevel']" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="医院地址">
							<el-input clearable v-model="hisHospital.address"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="医院电话">
							<el-input clearable v-model="hisHospital.phone"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="医院网址">
							<el-input clearable v-model="hisHospital.website"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="可否挂号">
							<el-select v-model="hisHospital.isRegistered" clearable class="w100">
								<el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :sm="12">
						<el-form-item label="是否同步患者信息">
							<el-select v-model="hisHospital.isSync" clearable class="w100">
								<el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :sm="12">
						<el-form-item label="经度">
							<el-input clearable v-model="hisHospital.longitude" placeholder="请前往高德地图复制经度"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="12">
						<el-form-item label="纬度">
							<el-input clearable v-model="hisHospital.latitude" placeholder="请前往高德地图复制纬度"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :sm="24">
						<el-form-item label="医院简介">
							<el-input clearable type="textarea" v-model="hisHospital.introduction"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :sm="24">
						<el-form-item label="医院图片">
							<div>
								<img v-if="hisHospital.hospitalUrlShow" :src="hisHospital.hospitalUrlShow" class="avatar" style="width: 200px;height: 150px;" @click="toggleHospImgDlgShow">
								<i v-else class="el-icon-plus avatar-uploader-icon" @click="toggleHospImgDlgShow"></i>
								<ImageCropper field="file" @crop-upload-success="uploadHospImgSuccess" @crop-upload-fail="uploadHospImgFail" v-model="isShowHospitalImgDlg" url="/api/upload" :width="400" :height="300" img-format="png"></ImageCropper>
							</div>

						</el-form-item>
					</el-col>
				</el-row>

			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="isShowHisHospital = false">取 消</el-button>
				<el-button size="small" @click="handleHisHospital" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="医生操作" v-dialogDrag :visible.sync="isShowExpertDlg">
			<el-form v-model="expert" class="w100">
				<el-form-item label="姓名">
					<el-input clearable v-model="expert.doctorName"></el-input>
				</el-form-item>
				<el-form-item label="出生日期">
					<el-date-picker clearable class="w100" v-model="expert.birthday" type="date">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="职称">
					<el-select clearable v-model="expert.title" class="w100">
						<el-option v-for="item in global.DictionaryData['title']" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="简介">
					<el-input clearable v-model="expert.introduction"></el-input>
				</el-form-item>
				<el-form-item label="头像">
					<div>
						<img v-if="expert.showHeadUrl" :src="expert.showHeadUrl" class="avatar" @click="toggleDoctorAvatarDlgShow">
						<i v-else class="el-icon-plus avatar-uploader-icon" @click="toggleDoctorAvatarDlgShow"></i>
						<ImageCropper field="file" @crop-upload-success="uploadDoctorAvatarSuccess" @crop-upload-fail="uploadHospImgFail" v-model="isShowDoctorAvatorDlg" url="/api/upload" img-format="png"></ImageCropper>
					</div>

				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="isShowExpertDlg = false">取 消</el-button>
				<el-button size="small" @click="handleExpert" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog v-dialogDrag title="同步随访患者信息" :visible.sync="isShowPatientDlg" width="30%">
			<el-form label-width="100px">
				<el-form-item label="开始时间">
					<el-date-picker
							size="small"
							v-model="beginDate"
							type="date"
							placeholder="选择日期"
							style="width: 100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间">
					<el-date-picker
							size="small"
							v-model="endDate"
							type="date"
							placeholder="选择日期"
							style="width: 100%">
					</el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
                <el-button plain size="small" @click="isShowPatientDlg = false">取 消</el-button>
                <el-button plain size="small" type="primary" @click="confirmPatient">确 定</el-button>
            </span>
		</el-dialog>

	</div>
</template>
<script>
    import { Hospital as HisHispital, Expert } from '@/utils/CommonClass.js'
    import { AddressCasc } from '@/utils/Address.js'
    // import 'babel-polyfill';
    import ImageCropper from '@/components/ImageCropper'

	class AddConfig{
        constructor() {
            this.orgId = null;
            this.url = null;
            this.appKey = 'd3a02bce2a0c1380f9fba875efc4d577';
        }

        reset() {
            this.constructor();
        }
	}

    class Config {
        constructor() {
            this.orgId = null;
            this.url = null;
            this.appKey = 'd3a02bce2a0c1380f9fba875efc4d577';
            this.configId = null;
        }

        reset() {
            this.constructor();
        }
    }

    class Hospital {
        constructor() {
            this.orgId = null;
            this.name = null;
        }
    }

    export default {
        components: {
            ImageCropper
        },
        data() {
            return {

                patientDlgVisible:false,
                dateValue:null,

                isShowHospitalImgDlg: false,
                isShowDoctorAvatorDlg: false,

				formConfig:new AddConfig(),

                formLabelWidth: "100",
                formVisible: false, //弹框是否显示
                formConfig: new Config(),
                tableData: [], //表格中使用的数据
                configTableData: [],
                departmentTableData: [],
                employeeTableData: [],
                tableId: null,
                editForm: false,
                title: null,
                formRules: {
                    orgId: [{
                        required: true,
                        message: '机构不能为空',
                        trigger: 'blur'
                    }],
                    url: [{
                        required: true,
                        message: '接口地址不能为空',
                        trigger: 'blur'
                    }],
                    appKey: [{
                        required: true,
                        message: '接口密钥不能为空',
                        trigger: 'blur'
                    }],
                },

                hospitalFormVisible: false,
                hospitalForm: new Hospital(),
                hospitalFormrules: {
                    orgId: [{
                        required: true,
                        message: '请选择机构',
                        trigger: 'change'
                    }, ],
                    name: [{
                        required: true,
                        message: '请输入医院名称',
                        trigger: 'blur'
                    }, ]
                },

                //编辑从HIS系统获取的医院数据
                isShowHisHospital: false,
                isEditHisHospital: false,
                hisHospital: new HisHispital(),
                address: new AddressCasc(),

                expertList: [], //专家列表
                isShowExpertDlg: false,
                expert: new Expert(),

				isShowPatientDlg: false,
				orgId: null,
                beginDate: null,
				endDate: null
            };
        },
        computed : {

        },
        methods: {
            handleChange(val){
                if(val){
                    let valArr=val.length;
                    let id=val[valArr-1];
					this.formConfig.orgId = id;
                }
            },
            onAddressBlur() {
                let addressText = this.address.getAddressText(this.hisHospital.area);
                console.log(addressText);
                if(addressText) {
                    this.hisHospital.address = addressText;
                }
            },
            handleItemChange(val) { //地址联动处理
                // this.address.handleItemChanged(val);
            },

            showHisHospital(row, isEdit) { //显示HIS医院DLG
                this.hisHospital.reset();
                Object.assign(this.hisHospital, row);
                if(this.hisHospital.hospitalUrl) {
                    this.hisHospital.hospitalUrlShow = IMAGE_BASE + this.hisHospital.hospitalUrl;
                }
                this.isShowHisHospital = true;
            },

            async handleHisHospital() {
				let res = await this.$http.post('updateHospital',this.hisHospital);
				if(res.state){
                    this.isShowHisHospital = false;
                    this.$message.success('操作成功');
                } else {
                    this.$message.error(res.msg);
                }
            },
            toggleHospImgDlgShow() {
                this.isShowHospitalImgDlg = !this.isShowHospitalImgDlg;
            },
            uploadHospImgSuccess(jsonData, field) {
                this.hisHospital.hospitalUrlShow = IMAGE_BASE + jsonData.data;
                this.hisHospital.hospitalUrl = jsonData.data;
            },
            uploadHospImgFail(status, field) {
                this.$message.error('上传失败！请返回上一步重试！');
            },
            toggleDoctorAvatarDlgShow() {
                this.isShowDoctorAvatorDlg = !this.isShowDoctorAvatorDlg;
            },
            uploadDoctorAvatarSuccess(jsonData, field) {
                this.expert.headUrl = jsonData.data;
                this.expert.showHeadUrl = IMAGE_BASE + jsonData.data;
            },

            async handleExpert() {
                if(this.expert.birthday) {
                    this.expert.birthday = this.getDate(this.expert.birthday);
                }
                let param = this.expert;
                let res = await this.$http.post('/sys/updHospitalDoctor',param);
                if(res.state) {
                    this.isShowExpertDlg = false;
                    this.$message.success("操作成功！");
                    this.getExpertList();
                } else {
                    this.$message.error(res.msg);
                }
            },
            showExpertDlg(row) {
                this.expert.reset();
                Object.assign(this.expert, row);
                this.expert.title = Number(this.expert.title);
                this.isShowExpertDlg = true;
            },

            formatTitle(row, column) {
                return this.getname('title', row.title);
            },

            formatSex(row, column) { //格式化性别
                return this.getname('sex', row.gender)
            },
            formatRegister(row, column) {
                if(1 == row.isRegistered) {
                    return '是';
                } else {
                    return '否';
                }
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            async load() {
                let res = await this.$http.post("/sys/getOrgApiConfig", {});
                if(res.state){
                    this.configTableData = res.data;
				}
            },
            add() {
                this.title = '新增配置';
                this.formVisible = true;
                this.formConfig.reset();
            },
            edit() {
                if(!this.currentRow) {
                    this.$message.error("请选择一条记录");
                    return;
                }
                this.title = '修改配置';
                this.formVisible = true;
                this.formConfig.reset();
                this.formConfig.orgId = this.currentRow.orgId;
                this.formConfig.configId = this.currentRow.configId;
                this.formConfig.appKey = this.currentRow.appKey;
                this.formConfig.url = this.currentRow.url;
            },
			async delConfigRequest(){
              let res = await this.$http.post('/sys/delOrgApiConfig',{configId:this.currentRow.configId});
              if(res.state){
                  this.$message.success("操作成功");
                  this.load();
              }
			},
            delConfig() {
                if(!this.currentRow) {
                    this.$message.error("请选择一条记录");
                    return;
                }
                this.$confirm('确定删除配置？', '提示', {}).then(() => {
                    this.delConfigRequest();
                });
            },
			async delHospitalRequest(){
                let res = await this.$http.post('/sys/delConfigHospital',{hospitalId: this.currentRow.hospitalId});
                if(res.state){
                    this.$message.success("操作成功");
                    this.load();
				}
			},
            delHospital(row) {
                if(!this.currentRow) {
                    this.$message.error("请选择一条记录");
                    return;
                }
                if(this.currentRow.hospitalId == null) {
                    this.$message.error("该记录未关联医院");
                    return;
                }
                this.$confirm('删除医院会同时删除科室、人员信息，是否确定删除？', '提示', {}).then(() => {
                    this.delHospitalRequest();
                });
            },
            async synHospitalRequest(orgId){
                let res = await this.$http.post('/sys/syncHospitalInfo',{orgId});
                if(res.state){
                    this.$message.success('同步成功');
                    this.load();
                }

            },
            synHospital(row) {
                this.$confirm('确定同步医院信息？', '提示', {}).then(() => {
                    this.synHospitalRequest(row.orgId);
                });
            },

            async synDepartmentRequest(row) {
                let param = {
                    orgId: row.orgId,
                    hospitalId: row.hospitalId
                };
                let res = await this.$http.post('/sys/syncDepInfo',param);
                if(res.state){
                    this.$message.success("同步科室信息成功");
                    this.getDepartment();
				}else{
                    this.$message.error("同步科室信息失败");
				}
            },
            synDepartment(row) {
                let data = {
                    orgId: row.orgId,
                    hospitalId: row.hospitalId
                };
                this.$confirm('确定同步科室信息？', '提示', {}).then(() => {
                   this.synDepartmentRequest(row);
                });
            },
            async synExpertRequest(row){
                let data = {
                    hospitalId: row.hospitalId
                };
                let res = await this.$http.post('/sys/syncDoctorInfo',data);
                if(res.state){
                    this.$message.success("同步医生信息成功");
                    this.getExpertList();
                }else{
                    this.$message.error("同步医生信息失败");
                }
            },
            synExpert(row) {
                this.$confirm('确定同步医生信息？', '提示', {}).then(() => {
                    this.synExpertRequest(row);
                });
            },
            async synPatientRequest(){
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let data = {
                    orgId: orgId,
					beginDate:this.getDate(this.beginDate),
					endDate:this.getDate(this.endDate),
					userId:sessionStorage.getItem('userId'),
                };
                let res = await this.$http.post('/fu/syncPatientInfo',data);
                if(res.state){
                    this.$message.success("同步随访患者信息成功");
                    this.isShowPatientDlg = false;
                }else{
                    this.$message.error("同步随访患者信息失败");
                }
            },
			confirmPatient(){
                if(!this.beginDate || !this.endDate){
                    this.$message.error('请选择同步时间段');
                    return;
				}
                this.$confirm('确定同步随访患者信息？', '提示', {}).then(() => {
                    this.synPatientRequest();
                });
			},
            synPatient(row) {
            	this.beginDate=null;
            	this.endDate=null;
                this.isShowPatientDlg = true;
                this.global.orgId = [];
                this.global.orgId.push(row.orgId);
            },
            async synRegisterdRequest(row){
                let data = {
                    hospitalId: row.hospitalId
                };
                let res = await this.$http.post('/sys/syncRegisteredDep',data);
                if(res.state){
                    this.$message.success("同步挂号科室成功");
                }else{
                    this.$message.error("同步挂号科室失败");
                }
			},
            synRegisterd(row){
                this.$confirm('确定同步挂号科室信息？', '提示', {}).then(() => {
                    this.synRegisterdRequest(row);
                });
			},
			async addOrgApi(){
              let res = await this.$http.post('/sys/addOrgApiConfig',this.formConfig);
              if(res.state){
                  this.$message.success("新增成功");
                  this.formVisible = false;
                  this.load();
              }
			},
            async updOrgApi(){
                let res = await this.$http.post('/sys/updOrgApiConfig',this.formConfig);
                if(res.state){
                    this.$message.success("修改成功");
                    this.formVisible = false;
                    this.load();
				}
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid) {
                        this.$confirm('确定提交吗？', '提示', {}).then(() => {
                            if(this.title == '新增配置') {
                                this.addOrgApi();
                            } else if(this.title == '修改配置') {
                                this.updOrgApi();
                            } else {
                                this.$message.error("不支持的操作");
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.formVisible = false;
            },
            changeOrganization(val) {
                this.formConfig.orgId = val;
            },
            generateAppKey() {
                this.formConfig.appKey = this.uuid();
            },
            uuid() {
                var s = [];
                var hexDigits = "0123456789abcdefghijklmnopqrstuvwxyz";
                for(var i = 0; i < 32; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                var uuid = s.join("");
                return uuid;
            },
            hospitalRowClick() {
                if(this.currentRow && this.currentRow.hospitalId) {
                    this.getDepartment();
                    this.getExpertList();
                } else {
                    this.departmentTableData = [];
                    this.employeeTableData = [];
                }
            },
            async getDepartment() {
                this.departmentTableData = [];
                let res = await this.$http.post('/sys/getHospitalDep',{hospitalId: this.currentRow.hospitalId});
                if(res.state){
                    this.departmentTableData = res.data;
				}else{
                    this.$message.error(res);
				}
            },
            async getExpertList() {
                let param = {
                    hospitalId: this.currentRow.hospitalId
                };
                let res = await this.$http.post('/sys/getHospitalDoctor',param);
                this.expertList = [];
                if(res.state) {
                    for(let item of res.data) {
                        let expert = new Expert();
                        Object.assign(expert, item);
                        expert.showHeadUrl = IMAGE_BASE + expert.headUrl;
                        this.expertList.push(expert);
                    }
                } else {
                    this.$message.error(res.msg);
                }
            },
            refresh() {
                this.load();
                this.departmentTableData = [];
                this.employeeTableData = [];
            }
        },
        created() {
            this.load();
        },
		mounted() {
			/*this.getoptions('hospitalLevel');
			this.getoptions('yesOrNo');
			this.getoptions('title');*/
		}
	};
</script>
<style scoped>
	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.avatar-uploader-icon:hover {
		border-color: #aaa;
	}

	.avatar {
		cursor: pointer;
		width: 120px;
		height: 120px;
		display: block;
	}

	.icon-file {
		padding-right: 7px;
		padding-left: 18px
	}

	.icon-folder,
	.icon-folder-open {
		padding-left: 7px;
		padding-right: 7px
	}
</style>